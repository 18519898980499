import { collection, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import Homeogo from "../../assets/icons/logo.png"
import { useLocation, useNavigate } from 'react-router-dom';
import { app } from '../../firebase';
const stripe = require('stripe')('sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK');
 const firestore = getFirestore(app)
function Request() {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [canceledItems, setCanceledItems] = useState([]);
    const [planData, setPlanData] = useState({}); // State to store plan data
    const [itemDetails, setItemDetails] = useState({});
    const navigate = useNavigate();

 
console.log(canceledItems);

   
    const location = useLocation();
    const { user } = location.state || {};
    console.log(user);
    useEffect(() => {
        const fetchRequestAndPlans = async () => {
            if (user?.uid) {
                console.log(user.name);

                const userCollection = collection(firestore, 'request');
                const q = query(userCollection, 
                    where('created_by', "==", user.uid)  
                   
                );
                console.log(q);

                try {
                    const querySnapshot = await getDocs(q);
                    console.log(querySnapshot);

                    if (!querySnapshot.empty) {
                        const data = querySnapshot.docs.map(doc => ({
                            id: doc.id, // Get the document ID
                            ...doc.data() // Spread the document data
                        }));

                        // Fetch the corresponding plan data based on plan_id
                        const planPromises = data.map(async (requestData) => {
                            if (requestData.plan_id) {
                                const planDocRef = doc(firestore, 'plans', requestData.plan_id);
                                const planDocSnap = await getDoc(planDocRef);
                                return { planId: requestData.plan_id, planData: planDocSnap.data() };
                            }
                            return null;
                        });

                        // Resolve all plan data fetches
                        const resolvedPlans = await Promise.all(planPromises);
                        const planDataMap = resolvedPlans.reduce((acc, plan) => {
                            if (plan) acc[plan.planId] = plan.planData;
                            return acc;
                        }, {});

                        setPlanData(planDataMap); // Store the plan data
                        setUserData(data);
                        fetchItemDetails(data);
                    } else {
                        setUserData(null);
                    }

                } catch (err) {
                    console.log("Error occurred:", err);
                }
            } else {
                console.log('User email not found.');
            }
        };
        const fetchItemDetails = async (data) => {
            const itemsToFetch = data.flatMap(request => 
                request.items_list.map(item => item.item_id) // Extract item IDs
            );

            const uniqueItemIds = [...new Set(itemsToFetch)]; // Get unique item IDs
            const itemPromises = uniqueItemIds.map(async (itemId) => {
                const itemDocRef = doc(firestore, 'items', itemId);
                const itemDocSnap = await getDoc(itemDocRef);
                return { id: itemId, data: itemDocSnap.data() }; // Return item data
            });

            const resolvedItems = await Promise.all(itemPromises);
            const itemDetailsMap = resolvedItems.reduce((acc, item) => {
                if (item.data) acc[item.id] = item.data; // Map item ID to item data
                return acc;
            }, {});

            setItemDetails(itemDetailsMap); // Update state with item details
        };

        fetchRequestAndPlans();
    }, [user]);





      // Function to handle subscription status change via Cloud Function
      const handleSubscriptionUpdate = async (docId, customerId,price,name, action) => {
        const subscriptionApiUrl = 'https://us-central1-kiip-51772.cloudfunctions.net/subscribe/subscribe'; // Replace with actual Cloud Function URL
        
        if (!customerId) {
            alert("Customer ID is missing.");
            return;
        }

        console.log("Customer ID:", customerId);
       

        try {
            setLoading(true)
            const response = await fetch(subscriptionApiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
                    'Content-Type': 'application/json',  // Changed to application/json for consistency
                },
                body: JSON.stringify({
                    customerId: customerId, // Correct the parameter name here
                    userId: user.uid,       // Pass user ID
                    requestId: docId,  // Subscription or request ID
                    price:price,
                    name:name,
                    action: action          // 'active' or 'cancel'
                })
            });
            setLoading(false)
            const result = await response.json();
            console.log(result);
            
            if (response.ok) {
                alert(`Subscription ${action} successfully!`);

                // Optionally update local state
                setUserData(prevData => prevData.map(item =>
                    item.id === docId ? { ...item, subscription_status: action } : item
                ));
            } else {
                alert(`Failed to ${action} subscription: ${result.error}`);
            }
        } catch (err) {
            console.log('Error handling subscription:', err);
            alert(`Failed to ${action} subscription.`);
        }
    };

   // Function to handle subscription cancellation
   const handleCancel = async (customerId, docId) => {
    console.log(customerId);
    console.log(docId);
    
    if (!customerId || !docId) {
        alert('Customer ID or document ID is missing.');
        return;
    }

    try {
        setLoading(true);

        // Retrieve the subscription from Stripe based on the customer ID
        const subscriptions = await stripe.subscriptions.list({ customer: customerId });
        // Find the specific subscription by matching the docId with the metadata
        const subscription = subscriptions.data.find(sub => sub.metadata && sub.metadata.requestId === docId);
       // const subscription = subscriptions.data[0]; // Assuming the first subscription is the one we want to cancel
       console.log(subscription);
       
        if (!subscription) {
            alert('No active subscription found for this customer.');
            setLoading(false);
            return;
        }

        // Cancel the subscription
        const canceledSubscription = await stripe.subscriptions.cancel(subscription.id);
        console.log('Subscription cancelled:', canceledSubscription);

        alert(`Subscription ${subscription.id} cancelled successfully!`);
        // Update Firestore document status to 'cancelled'
        const docRef = doc(firestore, 'request', docId);
        await updateDoc(docRef, { status: 'cancelled' });

        // Update local state to reflect subscription cancellation
        setCanceledItems(prev => [...prev, docId]);

        setUserData(prevData =>
            prevData.map(item =>
                item.id === docId ? { ...item, subscription_status: 'cancelled', status: 'cancelled' } : item
            )
        );
    } catch (error) {
        console.error('Error cancelling subscription:', error);
        alert('Failed to cancel subscription. Please try again later.');
    } finally {
        setLoading(false);
    }
};


const sortedUserData = userData?.length > 0 ? userData.slice().sort((a, b) => {
    const dateA = a.pickup_date_time ? a.pickup_date_time.seconds : 0;
    const dateB = b.pickup_date_time ? b.pickup_date_time.seconds : 0;
    return dateB - dateA;
}) : [];
    if (loading) return <div className='text-center mt-72'><p className=''>Loading...</p></div>;
  return (
    <div className=' min-h-screen max-h-cover w-screen'>
        <div className='sm:p-4 p-2   border-b-2'>
        <div className='flex flex-row gap-2 justify-items-center'>
            <div className='mt-3'>
                <img src={Homeogo} width={36} height={36} 
                className='' alt=''/>
               
                
            </div>
           
            <div className='flex flex-row gap-2'>
                <p className='text-5xl'>|</p>
             <h2 translate='no' className=' text-3xl mt-2'>Kiip</h2>
            </div>
        </div>
        </div>
        <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>
    <h2 className='text-center text-4xl font-bold mt-8  font-aeroport'>User Request Data</h2>
    { sortedUserData.length > 0 ? (
        <div className='  gap-4 items-center space-y-6 p-2 sm:mx-4 mx-1 w-auto     grid md:grid-cols-2  grid-cols-1'>
           {sortedUserData.map((data, index) => {
                const pickupDate = data.pickup_date_time
                    ? new Date(data.pickup_date_time.seconds * 1000).toLocaleDateString() // Convert Firebase Timestamp to a readable date
                    : 'No pickup date available';
                    const isCanceled = data.status === 'cancelled' || canceledItems.includes(data.id);
                    const currentPlan = planData[data.plan_id];
                return (
                    <div className='font-aeroport  mt-6 bg-gray-200 p-5 w-cover rounded-xl '  key={index}>
                        <strong>Document ID:</strong> {data.id} <br />
                        <div className=' grid grid-cols-1 sm:grid-cols-2  lg:gap-5 gap-2  mt-4'>
                        <strong>Pickup Date: <span className='font-normal'>{pickupDate}</span> </strong>
                        <strong>Location: <span className='font-normal'> {data.pickup_location}</span></strong>
                        <strong>Payment: <span className='font-normal'>${data.payment}</span></strong>
                        <strong>Status: <span className='font-normal'>{data.status || "Pending"}</span></strong>
                        </div>
                        {currentPlan ? (
                                        <div className='border-2 p-3 mt-8 bg-white sm:w-80'>
                                            <h1 className='text-center font-bold'>Plans:</h1>
                                            <h1 className='font-bold'>{currentPlan.name}</h1>
                                            <p className='font-normal'>{currentPlan.minimum}.0m x {currentPlan.minimum}.0m (up to {currentPlan.upto}m³)</p>
                                            <p className='font-bold'>{currentPlan.price} MXN</p>
                                        </div>
                                    ) : (
                                        <div>
                                             <p className="text-gray-800 font-bold mt-8 text-2xl">Items List:</p>
                                         {data.items_list && Array.isArray(data.items_list) ? (
                                             <ul className="list-disc list-inside text-gray-600 pl-6 mt-4 space-y-3">
                                             {data.items_list.map((item, idx) => (
                                               <span key={idx} className="text-lg">
                                                 {itemDetails[item.item_id] && (
                                                   <div className="text-gray-600">
                                                     <span>
                                                       <strong>Item:</strong>{" "}
                                                       {itemDetails[item.item_id].es_name}{" "}
                                                       <strong className="ml-3">Quantity:</strong>{" "}
                                                     {item.quantity}
                                    </span>
                                                   </div>
                                                 )}
                                               </span>
                                             ))}
                                          </ul>
                                         ) : (
                                             <div>No items found.</div>
                                         )}
                                    </div>
                                    )}
                        
                       
                         {/* Display multiple images */}
                {data.images && data.images.length > 0 && (
                  <div className='mt-4'>
                    <strong>Images:</strong>
                    <div className='flex flex-row  overflow-scroll gap-4 mt-2'>
                      {data.images.map((imageUrl, imgIndex) => (
                        <img
                          key={imgIndex}
                          src={imageUrl}
                          alt={`Request Image ${imgIndex + 1}`}
                          className='w-56 h-56 object-cover rounded-lg'
                        />
                      ))}
                    </div>
                  </div>
                )}
                        <div className='mt-8 sm:space-x-20 space-x-16 transition-all duration-300'>
                                    {!isCanceled && (
                                        <button
                                            
                                        >
                                         
                                        </button>
                                    )}

                                    {(data.status === "accepted" || data.status === "rejected") &&(

                                    <button
                                        onClick={() => handleCancel(data.customer_id, data.id)}
                                        className={`h-12 w-24 rounded-xl font-semibold text-lg transition-colors duration-300 ${isCanceled ? 'bg-black text-white' : 'bg-red-100 text-red-500'}`}
                                    >
                                        {isCanceled ? 'Cancelled' : 'Cancel'}
                                    </button>
                                    )}
                                </div>
                    </div>
                );
            })}
        </div>
    ) : (
        <div>No data found for this user.</div>
    )}
</div>
  )
}

export default Request
